import React from 'react'
import { NiceButton } from '../components/common/button'
import Layout from '../components/common/layout/Layout'

export default function Error404() {
  return (
    <Layout
      meta={{
        title: 'Not Found',
        description: 'The requested site could not be found ...',
      }}
      showNavbar={false}
    >
      <section className="flex items-center h-full p-16">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div className="max-w-md text-center">
            <div className="mb-8 font-extrabold text-9xl dark:text-gray-600">
              <span className="sr-only">Error</span>404
            </div>
            <p className="text-2xl font-semibold md:text-3xl">
              Sorry, we could not find this page.
            </p>
            <p className="mt-4 mb-8 dark:text-gray-400">
              But dont worry, you can find plenty of other things on our
              homepage.
            </p>
            <a rel="noopener noreferrer" href="/" className="">
              <NiceButton fullwidth variant="primary" size="big" type="submit">
                <p className="text-2xl">Back to homepage</p>
              </NiceButton>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}
